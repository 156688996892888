


<template>
  <div class="mx-auto" style="max-width: 1000px;">
    <Card class="card-custom">
      <template #title>
        <div class="column-wrapper align-center">
          <div class="column-three text-left"><back-button /></div>
            <h4 class="c-black flex-1 column-three">ID: {{getIdFromUrl()}}</h4>
            <div class="column-three"></div>
        </div>
        </template>
      <template #content>
        <ValidationObserver ref="observer" v-slot="{ invalid, validate }" v-if="!errorFlag">
            <Skeleton width="100%" height="500px" v-if="loadingPage"></Skeleton>
                <Card class="card-custom form-block" v-if="!loadingPage">
                  <template #title>
                    <h4>
                      {{ $t('min_dep') }} - {{ $t('max_dep') }}
                    </h4>
                    </template>
                  <template #content>
                    <div class="space-y-1">
                  <ValidationProvider
              :name="$t('currency')"
              rules="required"
              v-slot="{ errors, validate, validated }"
            > 
              <h4 class="c-text white-space title-input">{{ $t('currency') }}<span class="c-red">*</span>:</h4>
              <div class="wrapper-input d-flex flex-column">
                <MultiSelect
                :disabled="isAllCurrencies"
              v-model="selectedCurrencies"
              :options="currencies"
              class="custom-dropdown fw"
              style="flex: 1 1 auto"
              :class="{ 'p-invalid' : errors.length > 0 }"
              :placeholder="$t('currency')"
              :filter="true"
              :emptyFilterMessage="$t('no_data')"
            >
            </MultiSelect>
                <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
              </div>
          </ValidationProvider>
          <ValidationObserver v-slot="{ invalid, errors }" v-for="(limits, currency) in editBonus.currency_limits" :key="currency">
                    <h4 class="c-text white-space title-input">
                      {{ currency }}:
                    </h4>
                    <div class="d-flex flex-column fw">
                      <div class="d-flex md-flex-column gap-1 fw">
                        <ValidationProvider
                        tag="div"
                        class="d-flex flex-column fw"
                          name="min_dep"
                          :rules="{ required: true, min_less_than_max: { min: editBonus.currency_limits[currency].min_dep, max: editBonus.currency_limits[currency].max_dep } }"
                          v-slot="{ errors }"
                        >
                        <!-- :suffix="`${$t('min_dep')}: `" -->
                        <InputNumber 
                        locale="en-US"
                        class="flex-auto"
                        mode="currency"
                              :currency="currency"
                              currencyDisplay="code"
                              :placeholder="$t('amount')"
                              v-if="selectedCurrencies.includes(currency)"
                              v-model="editBonus.currency_limits[currency].min_dep"
                              :step="1"
                              :min="0"
                            />
                            <!-- <span>{{ errors[0] }}</span> -->
                        </ValidationProvider>
                        
                        <ValidationProvider
                        tag="div"
                        class="d-flex fw"
                          :name="$t('max_dep')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                        <!-- :prefix="`${$t('max_dep')}: `" -->
                            <InputNumber 
                              locale="en-US"
                              class="flex-auto"
                              mode="currency"
                              :currency="currency"
                              currencyDisplay="code"
                              :placeholder="$t('amount')"
                              v-if="selectedCurrencies.includes(currency)"
                              v-model="editBonus.currency_limits[currency].max_dep"
                              :step="1"
                              :min="1"
                            />
                            <!-- <span>{{ errors[0] }}</span> -->
                        </ValidationProvider>
                      </div>
                      <span class="p-error font-weight-bold" v-if="errors && errors.min_dep">{{ errors.min_dep[0] }}</span>
                    </div>
                </ValidationObserver>
                <Divider />
                <ValidationProvider
            :name="$t('bonus_percent')"
            rules="required"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('bonus_percent') }}<span class="c-red">*</span>:</h4>
            <div class="wrapper-input">
                <InputNumber 
            class="fw"
            :class="{ 'p-invalid' : errors.length > 0 }"
            :placeholder="$t('bonus_percent')" 
            v-model="editBonus.bonus_percent" 
            showButtons  
            suffix="%"
            :step="1"
            :min="0"
            :max="100"
            />
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
                <ValidationProvider
            :name="$t('wager')"
            rules="required"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('wager') }}<span class="c-red">*</span>:</h4>
            <div class="wrapper-input">
                <InputNumber 
            class="fw"
            :class="{ 'p-invalid' : errors.length > 0 }"
            :placeholder="$t('wager')" 
            v-model="editBonus.wager" 
            showButtons  
            prefix="x"
            :step="1"
            :min="0"
            :max="100"
            />
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
                <ValidationProvider
            :name="$t('expire_in_days')"
            rules="required|min:1"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('expire_in_days') }}<span class="c-red">*</span>:</h4>
            <div class="wrapper-input">
                <InputNumber 
            class="fw"
            :class="{ 'p-invalid' : errors.length > 0 }"
            :placeholder="$t('expire_in_days')" 
            v-model="editBonus.expire_in_days" 
            showButtons  
            :step="1"
            :min="1"
            />
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
                <ValidationProvider
            :name="$t('bonus_type')"
            rules="required"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('bonus_type') }}<span class="c-red">*</span>:</h4>
            <div class="wrapper-input d-flex flex-column">
              <Dropdown
              optionLabel="text"
              optionValue="value"
            v-model="editBonus.type"
            :options="types"
            class="custom-dropdown fw"
            style="flex: 1 1 auto"
            :class="{ 'p-invalid' : errors.length > 0 }"
            :placeholder="$t('bonus_type')"
            :filter="true"
            :emptyFilterMessage="$t('no_data')"
          >
          </Dropdown>
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
                <ValidationProvider
            :name="$t('type')"
            rules="required"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('type') }}<span class="c-red">*</span>:</h4>
            <div class="wrapper-input d-flex flex-column">
              <Dropdown
              optionLabel="text"
              optionValue="value"
            v-model="editBonus.sub_type"
            :options="subTypes"
            class="custom-dropdown fw"
            style="flex: 1 1 auto"
            :class="{ 'p-invalid' : errors.length > 0 }"
            :placeholder="$t('type')"
            :filter="true"
            :emptyFilterMessage="$t('no_data')"
          >
          </Dropdown>
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
                <ValidationProvider
            :name="$t('deposit_source')"
            rules="required"
            v-slot="{ errors, validate, validated }"
          > 
            <h4 class="c-text white-space title-input">{{ $t('deposit_source') }}<span class="c-red">*</span>:</h4>
            <div class="wrapper-input d-flex flex-column">
              <Dropdown
              optionLabel="name"
              dataKey="id"
              optionValue="name"
            v-model="editBonus.deposit_source"
            :options="getFilteredPayments"
            class="custom-dropdown fw"
            style="flex: 1 1 auto"
            :class="{ 'p-invalid' : errors.length > 0 }"
            :placeholder="$t('deposit_source')"
            :filter="true"
            :emptyFilterMessage="$t('no_data')"
          >
          </Dropdown>
              <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>
<span> 
<h4 class="c-text white-space title-input">
{{ $t('active') }}: 
</h4>
<div class="d-flex flex-column">
<InputSwitch class="p-inputswitch-success" v-model="editBonus.is_enabled" />
</div>
</span>
</div>
</template>
  </Card>
            <Button @click="handleSubmit(validate)" 
        :disabled="formLoading || loadingFlag || loadingPage" 
        :loading="formLoading || loadingFlag || loadingPage"
        :label="$t('edit')"
                icon="pi pi-save" class="p-button-sm p-button-success fw mt-2" />
        </ValidationObserver>
        <no-work-page 
        v-else
        @refresh-request="toGetBonusByID" 
        :loadingFlag="loadingFlag"
        textError="just_error"
        />
  </template>
</Card>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Api from "@/components/api";
export default {
    name: 'EditDepositBonus',
    computed: {
        ...mapGetters({
          editBonus: 'bonuses/getSelectedDepositBonus',
            payments: 'getPaymentWays',
            currencies: 'getFilteredCurrencies',
        }),
      getFilteredPayments() {
            return this.payments.filter(payment => payment.type === 'DEPOSIT');
        }
    },
    watch: {
      isAllCurrencies(newValue) {
    if (newValue) {
      // add currencies
        const currencies = Object.keys(this.currencies).map(currency => {
        return this.currencies[currency]; // Используйте название валюты вместо индекса
      });
      this.selectedCurrencies = currencies;
    } else {
      // to delete from selectedCurrencies
      this.selectedCurrencies = [];
    }
  },
  selectedCurrencies(newCurrencies) {
      if (!this.editBonus.currency_limits) {
      this.$set(this.editBonus, 'currency_limits', {});
    }
      for (const currency in this.editBonus.currency_limits) {
    if (!newCurrencies.includes(currency)) {
      // delete from selectedCurrencies and currency_limits
      delete this.editBonus.currency_limits[currency];
    }
  }
      newCurrencies.forEach(currency => {
        if (!this.editBonus.currency_limits[currency]) {
          this.$set(this.editBonus.currency_limits, currency, { min_dep: 100, max_dep: 500 });
        }
      });
    },
    editBonus: {
    handler(newBonus, oldBonus) {
      // editBonus
      // selectedCurrencies
      if (newBonus && newBonus.currency_limits) {
        
        this.selectedCurrencies = [];
        Object.keys(newBonus.currency_limits).forEach(currency => {
          this.selectedCurrencies.push(currency);
        });
      }
    },
    deep: true ,
    immediate: true
  }
  },
    data() {
        return {
        isAllCurrencies: false,
        loadingPage: true,
        loadingFlag: false,
        errorFlag: false,
        formLoading: false,
        selectedCurrencies: [
        ],
        subTypes: [
          { text: 'Non Sticky', value: 'NON_STICKY' },
            { text: 'Sticky', value: 'STICKY' }
        ],
        types: [
          {
            text: this.$t('welcome'),
            value: 'welcome' ,
          },
          {
            text: this.$t('next_deposit'),
            value: 'next_deposit',
          },
          {
            text: this.$t('no_deposit'),
            value: 'no_deposit',
          },
        ],
        }
    },
    async mounted() {
      this.loadingPage = true;
      const id = this.getIdFromUrl();
      if (this.editBonus.id !== id) {
            await this.toGetBonusByID();
        }
      if (!this.payments.length) {
        await this.$store.dispatch('getPaymentWays');
      }
      this.loadingPage = false;
    },
    methods: {
      async toGetBonusByID() {
        this.errorFlag = false;
        this.loadingFlag = true;
        try {
          const id = this.getIdFromUrl();
          await this.$store.dispatch('bonuses/getDepositBonusByID', id);
        } catch (error) {
          this.errorFlag = true;
          this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
        } finally {
          this.loadingFlag = false;
        }
      },
      getIdFromUrl() {
              const url = window.location.pathname;
              const parts = url.split('/'); 
              return Number(parts[parts.length - 1]);
            },
            handleSubmit(validate) {
            validate().then((success) => {
              if (success) {
                this.toEditBonus();
              }
            });
            },
      async toEditBonus(){
        this.formLoading = true;
        try {
            await this.$dispatch('bonuses/updateDepositBonus', this.editBonus);
            this.formLoading = false;
            this.$toast.add({ severity: 'success', summary: this.$t('bonus_updated'), life: 4000 });  
            this.$store.commit('bonuses/setEditDepBonus', false)
            await this.$store.dispatch('bonuses/getDepBonuses');
            
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 }); 
            
          } finally{
            this.formLoading = false;
          }
      },
    },
}
</script>

<style lang="scss" scoped>
.form-block{
  //max-height: 214px;
  //overflow: auto;
  display: flex;
  flex-direction: column;
  span{
    display: flex;
    align-items: center;
  }
  .title-input {
    flex: 0 0 200px;
    display: flex;
    //justify-content: flex-end;
    @media (max-width:500px){
      flex: 0 0 100px;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  }
  .wrapper-input {
    //flex: 0 0 200px;
   // width: 100%;
  flex: 1 1 auto;
  @media (max-width:500px){
    flex: 1 1 100%;
  }
  }
  
  .v-input{
    min-width: 170px;
    max-width: max-content;
      margin-right: 5px;
      //&:not(:last-child) {
      //}
      margin-bottom: 6px;
      &.error--text{
          margin-bottom: 0;
      }
  }
  &_height {
      flex-direction: column;
      align-items: stretch;
  }

  &__header {
  }

  &__inputs {
      display: flex;
      flex-wrap: wrap;
      .v-input{
          @media (minh:500px){
              max-width: 170px;
              
          }
      }
  }
}
.half {
  display: flex;
  @media (max-width:850px){
    flex: 1 1 100%;
  }    
  @media (min-width:850px){
    flex-direction: column;
      
  }
  .v-input{
    @media (max-width:850px){
      margin-right: 3px;
      max-width: 50%;
      min-width: 50%;
  }

  }
}
</style>